import React, { useState, useEffect } from "react"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import EditSettings from "../../components/organization-form/manageParentOrganizations/add-edit"
import { fetch_get } from "../../utils/fetch"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "gatsby"
import { FaArrowAltCircleLeft } from "react-icons/fa"

const ParentOrganizationSettings = props => {
    const { getAccessTokenSilently } = useAuth0()
    const [orgKey, setOrgKey] = useState('')

    useEffect(() => {
        getAccessTokenSilently()
            .then(accessToken =>
                fetch_get("parentOrganization/orgkey/" + props.orgKey, accessToken)
            )
            .then(response => {
                setOrgKey(response.org_id)
            })
    },[])

    return (
        <Layout pageTitle="Parent Organization Settings">
            <Header contentPage={true} className="contentPage">
                <h1 className="display-4 font-weight-bold">
                    <Link to={`/secure/organizations/`}>
                        <FaArrowAltCircleLeft className="goBackIcon" />
                    </Link>
                    Parent Organization Settings
                </h1>
            </Header>
            <Content>
                <EditSettings isDialog={false} orgKey={orgKey} />
            </Content>
        </Layout>
    )
}

export default ParentOrganizationSettings

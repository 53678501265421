import React, { useState, useEffect } from "react"
import { Form, FormGroup, Button, Label, Input, Col, CustomInput } from "reactstrap"
import { fetch_get, fetch_post } from "../../utils/fetch"
import { toast } from "react-toastify"
import { navigate } from "gatsby"
import { PropagateLoader } from "react-spinners"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import UserPicker from "../../components/support/input-search/add-access-picker"

const SUCCESS = "success"
const ERROR = "error"
export default function TeamForm(props) {
  const { getAccessTokenSilently } = useAuth0()
  const [teamName, setTeamName] = useState("")
  const [gitlabService, setGitlabService] = useState(false)
  const [wikiService, setWikiService] = useState(false)
  const [artifactoryService, setArtifactoryService] = useState(false)
  const [formInvalid, setFormInvalid] = useState(true)
  const [savingData, setSavingData] = useState(false)
  const [changes, setChanges] = useState("")
  const [gotError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [parentOrganization, setParentOrganization] = useState("")
  const [parList, setParList] = useState([])
  const [selectUsers, setSelectUsers] = useState([])
  const handleReset = () => {
    console.log("handleReset triggered")
    setTeamName("")
    document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0].click()
    document.getElementById("teamName").focus()
    setGitlabService(false)
    setArtifactoryService(false)
    setWikiService(false)
  }

  async function getResultStatus() {
    let patches = {}
    let url = ""

    patches["name"] = teamName
    patches["parentOrganization"] = parentOrganization
    patches["gitlab"] = gitlabService
    patches["artifactory"] = artifactoryService
    patches["wiki"] = wikiService
    patches["userNames"] = selectUsers.map(u => u.username)
    url = `ui/teams`

    return getAccessTokenSilently()
      .then(accessToken => fetch_post(url, patches, accessToken))
      .then(response => {
        return { status: SUCCESS, description: response.status.description }
      })
      .catch(error => {
        setLoading(false)
        Sentry.captureException(error)
        console.log(`Error while creating team from ${JSON.stringify(patches)}: ${error}`)
        // setGotError(true)
        return { status: ERROR, description: error }
      })
  }
  const handleSubmit = async event => {
    event.preventDefault()
    setSavingData(true)
    getResultStatus().then(res => {
      setSavingData(false)
      if (res.status === SUCCESS) {
        toast(`${res.description}`, {
          type: "success",
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => navigate(`/secure/teamadmin/`),
        })
      } else {
        toast(`${res.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    let mounted = true
    getAccessTokenSilently()
      .then(accessToken => fetch_get(`ui/organizations?ui=1`, accessToken))
      .then(res => {
        if (mounted) {
          const parListArr = Object.keys(res)
          setParList(parListArr)
          setParentOrganization(parListArr[0] || "")
          setLoading(false)
        }
      })
    return function cleanup() {
      mounted = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    var changeLog = []
    if (teamName !== "") {
      changeLog.push("Set team name")
    }
    if (parentOrganization !== "") {
      changeLog.push("Set parentOrganization")
    }
    if (gitlabService !== false) {
      changeLog.push("Enable Git service")
    }
    if (artifactoryService !== false) {
      changeLog.push("Enable Artifacts service")
    }
    if (wikiService !== false) {
      changeLog.push("Enable Wiki service")
    }
    setChanges(changeLog)
    if (changeLog.length === 0) {
      setFormInvalid(true)
    } else {
      setFormInvalid(false)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    teamName,
    parentOrganization,
    gitlabService,
    artifactoryService,
    wikiService,
  ])

  return loading ? (
    <Col
      className="d-flex justify-content-center align-items-center"
      xs={{ size: 12 }}
      sm={{ size: 4, offset: 4 }}
    >
      <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
    </Col>
  ) : !loading && gotError ? (
    <p>
      An error has occurred while trying to retrieve the information for this team. If this
      continues, please raise a support ticket.
    </p>
  ) : (
    <>
      <Col>
        <Form method="POST" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="teamName">Name</Label>
            <Input
              type="text"
              name="teamName"
              id="teamName"
              value={teamName}
              disabled={props.viewOnly}
              onChange={event => setTeamName(event.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="parentOrganization">Parent Organization</Label>
            <Input
              type="select"
              name="parentOrganization"
              id="parentOrganization"
              value={parentOrganization}
              onChange={event => setParentOrganization(event.target.value)}
            >
              {parList.map((p, index) => (
                <option value={p} key={index}>
                  {p}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="userNames">Users</Label>
            <UserPicker
              role='all'
              name="users"
              id="users"
              index={props.orgIndex}
              whitelist={[]}
              selectUsers={users => setSelectUsers(users)}
              addUsers={selectUsers}
              parentOrganization={parentOrganization}
            />
          </FormGroup>
          <h5>Services</h5>
          <FormGroup>
            <CustomInput
              type="switch"
              id="gitlabService"
              name="Git"
              label="Git"
              size={"lg"}
              checked={gitlabService}
              disabled={props.viewOnly}
              onChange={() => setGitlabService(!gitlabService)}
            />
          </FormGroup>
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <div class="mr-4">
              <FormGroup>
                <CustomInput
                  type="switch"
                  id="artifactorySwitch"
                  name="artifactoryService"
                  label="Artifacts"
                  size={"lg"}
                  checked={artifactoryService}
                  disabled={props.viewOnly}
                  onChange={() => setArtifactoryService(!artifactoryService)}
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup>
            <CustomInput
              type="switch"
              id="wikiService"
              name="wikiService"
              label="Wiki"
              size={"lg"}
              checked={wikiService}
              disabled={props.viewOnly}
              onChange={() => setWikiService(!wikiService)}
            />
          </FormGroup>
          <>
            <Button className="mr-2" onClick={handleReset} disabled={formInvalid || savingData}>
              Reset changes
            </Button>
            <Button
              type="submit"
              className="mr-2"
              color={"primary"}
              onClick={handleSubmit}
              disabled={formInvalid || savingData}
            >
              {savingData ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                  <span className="sr-only">Saving...</span>
                </span>
              ) : (
                "Create team"
              )}
            </Button>
          </>
        </Form>
        {!formInvalid ? (
          <div className="mt-3">
            <h5>Pending changes</h5>
            <ul>
              {changes.map((p, index) => (
                <li key={index}>{p}</li>
              ))}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </Col>
    </>
  )
}

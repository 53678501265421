import React, { useState } from "react"
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import Layout from "../../components/layout/layout"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import { Link } from "gatsby"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import { Loading } from "../../components/loading"

import classnames from "classnames"
import { css } from "glamor"
import AddUsers from "./bulk-users-addusers"
import Viewlogs from "./bulk-users-viewlogs"

const BulkUsers = () => {
  const { loading } = useUserOrgs()
  const [activeTab, setActiveTab] = useState("1")

  const newTabsName = [
    { value: "Add Users", id: "1" },
    { value: "View Logs", id: "2" },
  ]

  if (loading) {
    return <Loading />
  }

  const changeTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
    switch (tab) {
      case "1":
        break
      case "2":
        break
      default:
        break
    }
  }

  const navLink = tabsName => {
    return tabsName.map(item => {
      return (
        <NavItem key={item.id}>
          <NavLink
            className={classnames({ active: activeTab === item.id })}
            onClick={() => {
              changeTab(item.id)
            }}
            key={item.id}
            {...css({
              cursor: "pointer",
            })}
          >
            {item?.value}
          </NavLink>
        </NavItem>
      )
    })
  }

  return (
    <Layout pageTitle="Add Bulk Users">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">
          <Link to={`/secure/useradmin/`}>
            <FaArrowAltCircleLeft className="goBackIcon" />
          </Link>
          Add Bulk Users
        </h1>
      </Header>
      <Row>
        <Col xs={{ size: 1 }}>
          <Nav vertical className="nav-left">
            {navLink(newTabsName)}
          </Nav>
        </Col>
        <Col xs={{ size: 10 }}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <AddUsers />
            </TabPane>
            <TabPane tabId="2">
              <Viewlogs activeTab={activeTab} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Layout>
  )
}

export default BulkUsers

import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import TeamFormCreate from "../../components/team-form/team-form-create"
import { Link } from "gatsby"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import { Loading } from "../../components/loading"

const CreateTeam = props => {
  const { loading, currentOrg: { index } } = useUserOrgs()
  if (loading) {
    return <Loading />
  }
  return (
    <Layout pageTitle="Create Team">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">
          <Link to={`/secure/teamadmin/`}>
            <FaArrowAltCircleLeft className="goBackIcon" />
          </Link>
          Create Team
        </h1>
      </Header>
      <Content>
        <Row>
          <Col>
            <TeamFormCreate orgIndex={index} />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default CreateTeam

import React, { useState, useEffect } from "react"
import { Row, Col, CustomInput, Form, FormGroup, Button, Table } from "reactstrap"
import _ from "lodash"
import { fetch_post } from "../../utils/fetch"
import { toast } from "react-toastify"
import { useAuth0 } from "@auth0/auth0-react"
import ModalComponent from "../../components/modal/modal"
import ConfirmModal from "../../components/modal/confirm-modal"

const AddUsers = props => {
  const { getAccessTokenSilently } = useAuth0()
  const [file, setFile] = useState(null)
  const [usersData, setUsersData] = useState([])
  const [error, setError] = useState("")
  const [formRef, setFormRef] = useState(null)

  useEffect(() => {
    setFormRef(React.createRef())
  }, [])
  useEffect(() => {
    setError("")
    const reader = new FileReader()
    if (file) {
      reader.onload = function (e) {
        let result = e.target.result
        try {
          let users = []
          result = result.replace(/(\r\n)+/g, "\n").replace(/(\r)+/g, "\n")
          let usersInfo = result.split("\n")
          let keys = usersInfo.shift().split(",")
          let lastIndex = _.findLastIndex(usersInfo, function (o) {
            return o !== ""
          })
          for (let i = 0; i < lastIndex + 1; i++) {
            if (
              usersInfo[i].split(",").length !== 14 ||
              usersInfo[i].split(",").every(i => i === "")
            ) {
              setError(
                `Format Error: <br/>Line ${i + 1}: ${usersInfo[i]}. <br/>Please check your file.`
              )
              formRef.current.reset()
              return
            }
            let user = _.zipObject(keys, usersInfo[i].split(","))
            user.email = user.email.toLocaleLowerCase() === "true" ? true : false
            user.gitlab = user.git.toLocaleLowerCase() === "true" ? true : false
            user.servicedesk = user.support.toLocaleLowerCase() === "true" ? true : false
            user.artifactory = user.artifacts.toLocaleLowerCase() === "true" ? true : false
            user.wiki = user.wiki.toLocaleLowerCase() === "true" ? true : false
            user.welcomeEmail = user.welcomeEmail.toLocaleLowerCase() === "true" ? true : false
            users.push(user)
          }
          setUsersData(users)
        } catch (err) {
          formRef.current.reset()
          setError("File is illegal.")
          return
        }
      }
      reader.readAsText(file)
    }
  }, [file]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      const { rcModal } = props
      rcModal.showModal({
        title: "Error",
        width: 900,
        component: <ConfirmModal message={error} onClose={rcModal.closeModal} />,
      })
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  const upLoadFile = () => {
    getAccessTokenSilently()
      .then(accessToken => fetch_post("ui/users/bulk", usersData, accessToken))
      .then(response => {
        setFile(null)
        setUsersData([])
        formRef.current.reset()
        toast(`${response.status.description}`, {
          type: "success",
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(error => {
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  if (!formRef) {
    return null
  }

  return (
    <>
    <Col>
        <p>Please follow the steps below to add bulk users:
        <ol>
          <li>Download this <a href="https://www.codelinaro.org/users.csv">CSV template</a> file.</li>
          <li>Enter all required information into the CSV file as it pertains to each user.</li>
          <li>Upload (or drag-and-drop) your CSV file to this input box.</li>
          <li>Click the "Submit" button below.</li>
        </ol>
        </p>
      </Col>
      <Row>
        <Col xs={{ size: 8 }}>
          <Form innerRef={formRef}>
            <FormGroup>
              <CustomInput
                type="file"
                id="file"
                name="file"
                onChange={e => {
                  setUsersData([])
                  setFile(e.target.files[0])
                }}
              />
            </FormGroup>
          </Form>
        </Col>
        <Col xs={{ size: 4 }} style={{ fontSize: "16px" }}>
          <Button color={"primary"} onClick={() => upLoadFile()} disabled={!usersData.length}>
            Submit
          </Button>
        </Col>
      </Row>
      {usersData.length > 0 ? (
        <Table bordered>
          <thead>
            <tr>
              <th rowSpan={2}>Username</th>
              <th rowSpan={2}>First Name</th>
              <th rowSpan={2}>Last Name</th>
              <th rowSpan={2}>Display Name</th>
              <th rowSpan={2}>External Email Address</th>
              <th rowSpan={2}>Parent Organization</th>
              <th rowSpan={2}>Organization</th>
              <th rowSpan={2}>Role</th>
              <th colSpan={5}>Services</th>
              <th rowSpan={2}>Welcome Email</th>
            </tr>
            <tr>
              <th rowSpan={1}>Email</th>
              <th rowSpan={1}>Git</th>
              <th rowSpan={1}>Support</th>
              <th rowSpan={1}>Artifacts</th>
              <th rowSpan={1}>Wiki</th>
            </tr>
          </thead>
          <tbody>
            {usersData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.username}</td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.displayName}</td>
                  <td>{item.externalEmail}</td>
                  <td>{item.parentOrganization}</td>
                  <td>{item.organization}</td>
                  <td>{item.role}</td>
                  <td>{_.startCase(item.email.toString())}</td>
                  <td>{_.startCase(item.gitlab.toString())}</td>
                  <td>{_.startCase(item.servicedesk.toString())}</td>
                  <td>{_.startCase(item.artifactory.toString())}</td>
                  <td>{_.startCase(item.wiki.toString())}</td>
                  <td>{_.startCase(item.welcomeEmail.toString())}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : null}
    </>
  )
}

export default ModalComponent()(AddUsers)

import React, { useEffect } from "react"
import echarts from "echarts"
import { css } from "glamor"

export default function EchartsLine(props) {
  const drawBar = () => {
    const myEchart = echarts.init(document.getElementById(props.barId))
    const colorList = [
      "#A4C950",
      "#E37373",
      "#545FA9",
      "#AE67A6",
      "#C2C319",
      "#349292",
      "#CA9657",
      "#F18F59",
      "#638FEA",
      "#B99C2C",
    ]
    let tooltip = {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    }
    if (props.showOrgName) {
      tooltip = {
        ...tooltip,
        formatter:  function (params) {
          const orgName = props.barData.find(d=>d.name === params[0].axisValue).orgName
          return '<div>'+ params[0].axisValue +'</div>'+
            '<div style="display: flex"><div>'+params[0].marker + props.title + ':</div><div>' + params[0].value +'</div></div>'+
            '<div style="display: flex"><div>&nbsp;&nbsp;&nbsp;Organization Name'  + ':</div><div>' + orgName +'</div></div>'
        } ,
      }
    }
    const option = {
      legend: {
        orient: "vertical",
        left: 10,
        data: props.barData.length > 0 ? props.barData.map(i => i.name) : [],
      },
      tooltip,
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: props.barData.length > 0 ? props.barData.map(i => i.name) : [],
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      "dataZoom": [{
        show: true,
        height: 12,
        xAxisIndex: [
          0
        ],
        bottom: '8%',
        startValue: 0,
        endValue: 19,
        handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
        handleSize: '110%',
        handleStyle: {
          color: '#d3dee5',

        },
        textStyle: {
          color: 'transparent'
        },
        borderColor: '#90979c'
      }, {
        type: 'inside',
        show: true,
        height: 15,
        start: 1,
        end: 35
      }],
      series: [
        {
          name: props.title ? props.title : "",
          type: "line",
          barWidth: 20,
          label: {
            normal: {
              show: true,
              fontSize: 14,
              fontWeight: "bold",
              color: function (params) {
                return colorList[params.dataIndex]
              },
              position: "top",
            },
          },
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
            },
          },
          data: props.barData.length > 0 ? props.barData.map(i => i.value) : [],
        },
      ],
    }

    if (props.barData.length<=20) delete option.dataZoom
    myEchart.setOption(option)
    window.addEventListener("resize", () => {
      myEchart.resize()
    })
  }

  useEffect(() => {
    if (props.barData.length > 0 && document.getElementById(props.barId)) {
      drawBar()
    }
  })

  return (
    <div {...css(styles.borderStyle)} id={props.barId}>
      {props.barData.length > 0 ? null : (
        <div>
          <img src="/empty.svg" alt="empty" />
          <div {...css(styles.noData)}>No Data</div>
        </div>
      )}
    </div>
  )
}

const styles = {
  borderStyle: {
    textAlign: "center",
    width: "100%",
    height: "100%",
  },
  noData: {
    color: "#bfbfbf",
    fontSize: 18,
  },
}
import React from "react"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Content from "../components/content/content"

export default function ErrorPage() {
  const browser = typeof window !== "undefined" && window

  return (
    browser && (
      <>
        <Layout pageTitle="oops! Page not found">
          <Header headerText="oops! Page not found: 404" />
          <Content></Content>
        </Layout>
      </>
    )
  )
}

import React, { useState } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { fetch_get } from "../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"

export default function Tags(props) {
  const { getAccessTokenSilently } = useAuth0()
  const [filterUsers, setFilterUsers] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [errorMeaasge, setErrorMeaasge] = useState("")

  const getUsers = (event, searchString, reason) => {
    setErrorMeaasge("")
    setFilterUsers([])
    if (searchString) {
      let url = props.index
        ? `users?index=${props.index}&page=1&query=${searchString}`
        : `users?page=1&query=${searchString}&parentOrganization=${props.parentOrganization}`
      setLoadingData(true)
      getAccessTokenSilently()
        .then(accessToken => fetch_get(url, accessToken))
        .then(response => {
          const users = props.whitelist.concat(props.addUsers)
          setFilterUsers(_.differenceBy(response["data"] || [], users, "username"))
          setLoadingData(false)
        })
        .catch(error => {
          console.log(`Error while getting list of users: ${error}`)
          Sentry.captureException(error)
          setFilterUsers([])
          setErrorMeaasge("An error occurred while getting the list of users.")
          setLoadingData(false)
        })
    } else {
      setFilterUsers([])
      setLoadingData(false)
    }
  }

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      key={props.role}
      loading={loadingData}
      noOptionsText={
        loadingData ? "Loading..." : errorMeaasge ? errorMeaasge : "No user to select."
      }
      options={filterUsers}
      filterOptions={option => option}
      getOptionLabel={option => option.displayName}
      onInputChange={_.debounce(getUsers, 500)}
      onChange={(event, value, reason) => {
        props.selectUsers(value)
      }}
      filterSelectedOptions
      renderOption={option => {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div style={{ width: "100%", padding: "6px 16px" }}>{option.displayName}</div>
          </>
        )
      }}
      renderInput={params => (
        <TextField {...params} variant="outlined" label="Search for users" placeholder="Users" />
      )}
    />
  )
}

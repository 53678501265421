import React from "react"
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react"
import { useUserOrgs } from "../hooks/use-user"
import { Loading } from "./loading"
import Layout from "./layout/layout"
import Content from "./content/content"
import Header from "./header/header"

const PermissionDeniedPage = (
  <Layout pageTitle="Permission Denied">
    <Header contentPage={true} className="contentPage">
      <h1 className="display-4 font-weight-bold">Permission Denied</h1>
    </Header>
    <Content>
      <p>You have no permission to access this page.</p>
    </Content>
  </Layout>
)

const SuperAdminPage = ({ component: Component, ...rest }) => {
  const {
    loading,
    currentOrg: {
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { user } = useAuth0()
  if (loading) {
    return <Loading />
  }

  if (!isSuperAdmin) {
    if (rest.allow) {
      // const isOperate = (user["https://api.codelinaro.org/single-project-admin"]?.find(p=>p.parentOrganization === name)?.projects||[]).find(
      //   p => p.slug === rest.projectSlug
      // )
      const isOperate = user["https://api.codelinaro.org/single-project-admin"]
        ?.find(p => p.parentOrganization === name)
        ?.projects
        ?.find(p => p.slug === rest.projectSlug) ?? null;

      if (isOperate) return <Component {...rest} />
    }
    return PermissionDeniedPage
  }

  return <Component {...rest} />
}

export const SuperAdminRoute = ({ ...args }) => {
  const PrivateComponent = withAuthenticationRequired(SuperAdminPage)
  return <PrivateComponent {...args} />
}

const SystemAdminPage = ({ component: Component, ...rest }) => {
  const {
    loading,
    currentOrg: {
      name,
      role: { isSystemAdmin },
    },
  } = useUserOrgs()
  const { user } = useAuth0()
  if (loading) {
    return <Loading />
  }
  if (!isSystemAdmin) {
    if (rest.allow) {
      // const isOperate = (user["https://api.codelinaro.org/single-project-admin"]?.find(p=>p.parentOrganization === name)?.projects||[]).find(
      //   p => p.slug === rest.projectSlug
      // )
      const isOperate = user["https://api.codelinaro.org/single-project-admin"]
        ?.find(p => p.parentOrganization === name)
        ?.projects
        ?.find(p => p.slug === rest.projectSlug) ?? null;

      if (isOperate) return <Component {...rest} />
    }
    return PermissionDeniedPage
  }
  return <Component {...rest} />
}

const ProjectMetricsPage = ({ component: Component, ...rest }) => {
  const { loading } = useUserOrgs()

  if (loading) {
    return <Loading />
  }
  return <Component {...rest} />
}

export const ProjectMetricsRoute = ({ ...args }) => {
  const PrivateComponent = withAuthenticationRequired(ProjectMetricsPage)
  return <PrivateComponent {...args} />
}

export const SystemAdminRoute = ({ ...args }) => {
  const PrivateComponent = withAuthenticationRequired(SystemAdminPage)
  return <PrivateComponent {...args} />
}

const ProjectAdminPage = ({ component: Component, ...rest }) => {
  const {
    loading,
    currentOrg: {
      role: { isProjectAdmin },
    },
  } = useUserOrgs()
  if (loading) {
    return <Loading />
  }

  if (!isProjectAdmin) {
    return PermissionDeniedPage
  }

  return <Component {...rest} />
}

export const ProjectAdminRoute = ({ ...args }) => {
  const PrivateComponent = withAuthenticationRequired(ProjectAdminPage)
  return <PrivateComponent {...args} />
}

const UserAdminPage = ({ component: Component, ...rest }) => {
  const {
    loading,
    currentOrg: {
      role: { isUserAdmin },
    },
  } = useUserOrgs()
  if (loading) {
    return <Loading />
  }

  if (!isUserAdmin) {
    return PermissionDeniedPage
  }

  return <Component {...rest} />
}

export const UserAdminRoute = ({ ...args }) => {
  const PrivateComponent = withAuthenticationRequired(UserAdminPage)
  return <PrivateComponent {...args} />
}

const AdminPage = ({ component: Component, ...rest }) => {
  const {
    loading,
    currentOrg: {
      role: { isSystemAdmin, isProjectAdmin, isUserAdmin, isSuperAdmin },
    },
  } = useUserOrgs()

  if (loading) {
    return <Loading />
  }

  if (!isSystemAdmin && !isProjectAdmin && !isUserAdmin && !isSuperAdmin) {
    return PermissionDeniedPage
  }

  return <Component {...rest} />
}

export const AdminRoute = ({ ...args }) => {
  const PrivateComponent = withAuthenticationRequired(AdminPage)
  return <PrivateComponent {...args} />
}

import React, { Component, useContext, useState, useEffect } from "react"
import { Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"

import { SSContext } from "./context"
import classnames from "classnames"
import { css } from "glamor"
import _ from "lodash"
import ServicesCost from "./controls/services-cost"

function SSIndex(props) {
  let contextData = useContext(SSContext)
  var [activeTab, setActiveTab] = React.useState("1")
  const changeTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <Nav tabs>
        <NavItem key={"1"}>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              changeTab("1")
            }}
            key={"1"}
            {...css(styles.navLink)}
          >
            Services Cost Settings
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <>
            <ServicesCost />
          </>
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

export default SSIndex

const styles = {
  box: {
    position: "relative",
  },
  tabContent: {
    height: "100%",
    width: "100%",
    marginTop: "25px",
  },
  navLink: {
    cursor: "pointer",
  },
}

import React from "react"
import { Row, Col, Button } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import ProjectFormEdit from "../../components/project-form/project-form-edit"
import { Loading } from "../../components/loading"
import { css } from "glamor"
import { navigate } from "gatsby"

const ProjectEdit = props => {
  const {
    loading,
    currentOrg: {
      index
    },
  } = useUserOrgs()

  if (loading) {
    return <Loading />
  }

  return (
    <Layout pageTitle="Project Edit">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">Project Edit</h1>
      </Header>
      <Content>
        <Row>
          <Col>
            <ProjectFormEdit
              orgIndex={index}
              mode="jumpTo"
              projectSlug={props.projectSlug}
              parentOrganization={props.parentOrganization}
              onProjectNameChange={() => {}}
              onProjectCancel={() => {}}
            />
          </Col>
        </Row>
        {props.location.state?.from ? (
          <Row>
            <Col
              {...css({
                textAlign: "right",
                paddingRight: "15px",
              })}
            >
              <Button
                onClick={() => {
                  navigate(props.location.state.from)
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        ) : null}
      </Content>
    </Layout>
  )
}

export default ProjectEdit

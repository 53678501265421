import React, { useState, useEffect } from "react"
import { Form, FormGroup, Button, Label, Input, Col, CustomInput } from "reactstrap"
import { fetch_get, fetch_post } from "../../utils/fetch"
import { toast } from "react-toastify"
import { navigate } from "gatsby"
import { PropagateLoader } from "react-spinners"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { useUserOrgs } from "../../hooks/use-user"
import { EditorState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const SUCCESS = "success"
const ERROR = "error"
export default function ProjectForm(props) {
  // Workgroup details are initialised in the useEffect below so that
  // they can be updated whenever the slug changes.
  const { getAccessTokenSilently } = useAuth0()
  const [eccn, setEccn] = useState("")
  const [projectName, setProjectName] = useState("")
  const [originalProjectName] = useState("")
  const [projectDescription, setProjectDescription] = useState(EditorState.createEmpty())
  const [originalProjectDescription] = useState(EditorState.createEmpty())
  const [projectSlug, setProjectSlug] = useState("")
  const [originalProjectSlug, setOriginalProjectSlug] = useState("")
  const [projectVisibility, setProjectVisibility] = useState("public")
  const [originalProjectVisibility] = useState("public")
  const [originalExternalContribution, setOriginalExternalContribution] = useState(false)
  const [externalContribution, setExternalContribution] = useState(false)
  const [externalContributionAllowed, setExternalContributionAllowed] = useState(false)
  const [servicesEnable, setServicesEnable] = useState({})
  const [gitlabService, setGitlabService] = useState(false)
  const [groupPath, setGroupPath] = useState("")
  const [groupPaths, setGroupPaths] = useState([])
  const [wikiService, setWikiService] = useState(false)
  const [artifactoryService, setArtifactoryService] = useState(false)
  const [formInvalid, setFormInvalid] = useState(true)
  const [savingData, setSavingData] = useState(false)
  const [changes, setChanges] = useState("")
  const [gotError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [parentOrganization, setParentOrganization] = useState("")
  const [organization, setOrganization] = useState("")
  const [orgList, setOrgList] = useState([])
  const [orgNewList, setOrgNewList] = useState([])
  const [parList, setParList] = useState([])
  const [repoType, setRepoType] = useState("generic")
  const [repoName, setRepoName] = useState("")
  const repoTypes = [
    "alpine",
    "maven",
    "gradle",
    "ivy",
    "sbt",
    "helm",
    "cargo",
    "cocoapods",
    "opkg",
    "rpm",
    "nuget",
    "cran",
    "gems",
    "npm",
    "bower",
    "debian",
    "composer",
    "pypi",
    "docker",
    "vagrant",
    "gitlfs",
    "go",
    "yum",
    "conan",
    "chef",
    "puppet",
    "generic",
  ]
  const {
    currentOrg: {
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  const handleReset = () => {
    console.log("handleReset triggered")
    setProjectName(originalProjectName)
    setProjectDescription(originalProjectDescription)
    setProjectSlug(originalProjectSlug)
    setProjectVisibility(originalProjectVisibility)
    setEccn("")
    setGitlabService(false)
    setGroupPath("")
    setGroupPaths([])
    setArtifactoryService(false)
    setWikiService(false)
  }

  async function getResultStatus() {
    let patches = {}
    let url = ""

    patches["name"] = projectName
    patches["description"] = draftToHtml(convertToRaw(projectDescription.getCurrentContent()))
    patches["projectSlug"] = projectSlug
    if (eccn) patches["eccn"] = eccn
    patches["visibility"] = projectVisibility

    if (isSuperAdmin) {
      patches["parentOrganization"] = parentOrganization
    }

    patches["organization"] = organization
    if(servicesEnable.git_service_enabled) patches["gitlab"] = gitlabService

    if (servicesEnable.git_service_enabled && gitlabService && groupPath) {
      patches["path"] = groupPath
    }

    if (servicesEnable.artifactory_service_enabled) patches["artifactory"] = artifactoryService
    if (servicesEnable.artifactory_service_enabled && artifactoryService && repoType) {
      patches["repoType"] = repoType
      patches["repoName"] = repoName || projectSlug
    }
    if (servicesEnable.wiki_service_enabled && projectVisibility !== "private") {
      patches["wiki"] = wikiService
    }

    if (externalContributionAllowed) patches["externalContribution"] = externalContribution

    url = `ui/projects${isSuperAdmin ? "" : `?index=${props.orgIndex}`}`

    return getAccessTokenSilently()
      .then(accessToken => fetch_post(url, patches, accessToken))
      .then(response => {
        return { status: SUCCESS, description: response.status.description }
      })
      .catch(error => {
        setLoading(false)
        Sentry.captureException(error)
        console.log(`Error while exporting project from ${JSON.stringify(patches)}: ${error}`)
        // setGotError(true)
        return { status: ERROR, description: error }
      })
  }
  const handleSubmit = async event => {
    event.preventDefault()
    setSavingData(true)
    getResultStatus().then(res => {
      setSavingData(false)
      if (res.status === SUCCESS) {
        toast(`${res.description}`, {
          type: "success",
          position: "bottom-left",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => navigate(`/secure/projectadmin/`),
        })
      } else {
        toast(`${res.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })
  }

  useEffect(() => {
    if (parentOrganization !== "" && parList.length > 0) {
      const orgItem = orgList[parentOrganization]
      setOrgNewList(orgItem)
      setOrganization(orgItem[0])
    }
  }, [parentOrganization, orgList, parList])

  useEffect(() => {
    if (parentOrganization && gitlabService) {
      setGroupPaths([])
      setGroupPath("")
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `parentOrganization/${parentOrganization}/git/subgroups?all=true&visibility=${projectVisibility}`,
            accessToken
          )
        )
        .then(res => {
          setGroupPaths(res.data)
          setGroupPath(res.data[0]?.relativePath)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gitlabService, parentOrganization, projectVisibility])

  useEffect(() => {
    setLoading(true)
    let mounted = true
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          `ui/organizations?ui=1${isSuperAdmin ? "" : `&index=${props.orgIndex}`}`,
          accessToken
        )
      )
      .then(res => {
        if (mounted) {
          const parListArr = Object.keys(res)
          const orgItem = res[parListArr[0]] || []
          setParList(parListArr)
          setOrgList(res)
          setOrgNewList(orgItem)
          setParentOrganization(parListArr[0] || "")
          setOrganization(orgItem[0] || "")
          setLoading(false)
        }
      })
    return function cleanup() {
      mounted = false
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const slug = projectName
      .replace(/[^A-Za-z0-9\-\s]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
    setOriginalProjectSlug(slug)
    if (projectSlug === originalProjectSlug) {
      setProjectSlug(slug)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName])

  const checkExternalContributionIsAllowedOnParentOrganization = () => {
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(
          `parentOrganization/${parentOrganization}/externalcontribution/allowed`,
          accessToken
        )
      )
      .then(res => {
        setExternalContributionAllowed(res)
      })
  }

  const getParentOrgInfo = () => {
    getAccessTokenSilently()
    .then(accessToken =>
      fetch_get(
        `parentOrganization/services/${parentOrganization}`,
        accessToken
      )
    )
    .then(res => {
      setServicesEnable(res)
    }).catch(()=>{
    })
  }

  useEffect(() => {
    if (
      parentOrganization === undefined ||
      parentOrganization === null ||
      parentOrganization === ""
    )
      return
    setExternalContribution(false)
    setOriginalExternalContribution(false)
    getParentOrgInfo()
    checkExternalContributionIsAllowedOnParentOrganization()
  }, [parentOrganization])

  useEffect(() => {
    var changeLog = []
    if (projectName !== originalProjectName) {
      changeLog.push("Set project name")
    }
    if (
      draftToHtml(convertToRaw(projectDescription.getCurrentContent())) !==
      draftToHtml(convertToRaw(originalProjectDescription.getCurrentContent()))
    ) {
      changeLog.push("Set project description")
    }
    if (projectSlug !== originalProjectSlug) {
      changeLog.push("Set project slug")
    }
    if (eccn !== "") {
      changeLog.push("Set project ECCN")
    }
    if (projectVisibility !== originalProjectVisibility) {
      changeLog.push("Set project visibility")
    }
    if (parentOrganization !== "") {
      changeLog.push("Set parentOrganization")
    }
    if (organization !== "") {
      changeLog.push("Set organization")
    }
    if (gitlabService !== false) {
      changeLog.push("Enable Git service")
    }
    if (artifactoryService !== false) {
      changeLog.push("Enable Artifacts service")
    }
    if (projectVisibility === "public" && wikiService !== false) {
      changeLog.push("Enable Wiki service")
    }
    if (externalContributionAllowed && externalContribution !== originalExternalContribution) {
      changeLog.push("Set External Contribution")
    }
    setChanges(changeLog)
    if (changeLog.length === 0) {
      setFormInvalid(true)
    } else {
      setFormInvalid(false)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectName,
    projectDescription,
    projectSlug,
    eccn,
    projectVisibility,
    gitlabService,
    wikiService,
    artifactoryService,
    originalProjectName,
    originalProjectDescription,
    originalProjectSlug,
    originalProjectVisibility,
    externalContribution,
    originalExternalContribution,
  ])

  const handleExternalContributionChange = e => {
    setExternalContribution(e.target.checked)
  }

  return loading ? (
    <Col
      className="d-flex justify-content-center align-items-center"
      xs={{ size: 12 }}
      sm={{ size: 4, offset: 4 }}
    >
      <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
    </Col>
  ) : !loading && gotError ? (
    <p>
      An error has occurred while trying to retrieve the information for this project. If this
      continues, please raise a support ticket.
    </p>
  ) : (
    <>
      <Col>
        <Form method="POST" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="projectName">Name</Label>
            <Input
              type="text"
              name="projectName"
              id="projectName"
              value={projectName}
              disabled={props.viewOnly}
              onChange={event => setProjectName(event.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="projectDescription">Description</Label>
            <Editor
              toolbar={{
                options: ["inline", "list", "link"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
              editorState={projectDescription}
              editorClassName="editorMain"
              onEditorStateChange={event => {
                setProjectDescription(event)
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="projectSlug">Slug</Label>
            <Input
              type="text"
              name="projectSlug"
              id="projectSlug"
              value={projectSlug}
              onChange={event => setProjectSlug(event.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="projectName">Export Control Classification Number (ECCN)</Label>
            <Input
              type="text"
              name="eccn"
              id="eccn"
              value={eccn}
              disabled={props.viewOnly}
              onChange={event => setEccn(event.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="projectVisibility">Visibility</Label>
            <Input
              type="select"
              name="projectVisibility"
              id="projectVisibility1111"
              value={projectVisibility}
              onChange={event => setProjectVisibility(event.target.value)}
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="parentOrganization">Parent Organization</Label>
            <Input
              type="select"
              name="parentOrganization"
              id="parentOrganization"
              value={parentOrganization}
              disabled={!isSuperAdmin}
              onChange={event => setParentOrganization(event.target.value)}
            >
              {parList.map((p, index) => (
                <option value={p} key={index}>
                  {p}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="organization">Organization</Label>
            <Input
              type="select"
              name="organization"
              id="organization"
              value={organization}
              onChange={event => setOrganization(event.target.value)}
            >
              {orgNewList.map((p, index) => (
                <option value={p} key={index}>
                  {p}
                </option>
              ))}
            </Input>
          </FormGroup>
          {externalContributionAllowed && (
            <>
              <FormGroup>
                <CustomInput
                  type="switch"
                  id="externalContribution"
                  name="externalContribution"
                  label="External Contribution"
                  size={"lg"}
                  checked={externalContribution}
                  onChange={e => handleExternalContributionChange(e)}
                />
              </FormGroup>
            </>
          )}
          {(servicesEnable.git_service_enabled || servicesEnable.artifactory_service_enabled || servicesEnable.wiki_service_enabled) && <h5>Services</h5>}
          {servicesEnable.git_service_enabled && <div>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <div class="mr-4">
                <FormGroup>
                  <CustomInput
                    type="switch"
                    id="gitlabService"
                    name="Git"
                    label="Git"
                    size={"lg"}
                    checked={gitlabService}
                    disabled={props.viewOnly}
                    onChange={() => setGitlabService(!gitlabService)}
                  />
                </FormGroup>
              </div>
              {gitlabService ? (
                <div>
                  <FormGroup>
                    <Input
                      type="select"
                      name="groupPath"
                      id="groupPath"
                      value={groupPath}
                      onChange={event => setGroupPath(event.target.value)}
                    >
                      {groupPaths.map(p => (
                        <option value={p.relativePath} key={p.gitGroupID}>
                          {p.path}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
              ) : null}
            </div>
          </div>}
          {servicesEnable.artifactory_service_enabled && <div>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <div class="mr-4">
                <FormGroup>
                  <CustomInput
                    type="switch"
                    id="artifactorySwitch"
                    name="artifactoryService"
                    label="Artifacts"
                    size={"lg"}
                    checked={artifactoryService}
                    disabled={props.viewOnly}
                    onChange={() => setArtifactoryService(!artifactoryService)}
                  />
                </FormGroup>
              </div>
              {artifactoryService ? (
                <>
                  <div class="mr-4">
                    <FormGroup>
                      <Input
                        type="text"
                        name="repoName"
                        id="repoName"
                        value={repoName}
                        disabled={props.viewOnly}
                        onChange={event => setRepoName(event.target.value)}
                        placeholder="Repo Name"
                      />
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup>
                      <Input
                        type="select"
                        name="repoType"
                        id="repoType"
                        value={repoType}
                        onChange={event => setRepoType(event.target.value)}
                      >
                        {repoTypes.map(p => (
                          <option value={p} key={p}>
                            {p}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                </>
              ) : null}
            </div>
          </div>}
          {/* {projectVisibility !== "private" ? ( */}
            {servicesEnable.wiki_service_enabled && <FormGroup>
            <CustomInput
              type="switch"
              id="wikiService"
              name="wikiService"
              label="Wiki"
              size={"lg"}
              checked={wikiService}
              disabled={props.viewOnly}
              onChange={() => setWikiService(!wikiService)}
            />
          </FormGroup>}
          {/* ) : null} */}

          <>
            <Button className="mr-2" onClick={handleReset} disabled={formInvalid || savingData}>
              Reset changes
            </Button>
            <Button
              type="submit"
              className="mr-2"
              color={"primary"}
              onClick={handleSubmit}
              disabled={formInvalid || savingData}
            >
              {savingData ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                  <span className="sr-only">Saving...</span>
                </span>
              ) : (
                "Create project"
              )}
            </Button>
          </>
        </Form>
        {!formInvalid ? (
          <div className="mt-3">
            <h5>Pending changes</h5>
            <ul>
              {changes.map((p, index) => (
                <li key={index}>{p}</li>
              ))}
            </ul>
          </div>
        ) : (
          <></>
        )}
      </Col>
    </>
  )
}

import React, { useEffect, useContext, useState } from "react"
import { SSContext } from "../context"
import {
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from "reactstrap"
import _ from "lodash"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"

function ServicesCost(props) {
  let contextData = useContext(SSContext)
  const { getAccessTokenSilently } = useAuth0()
  var [form, setForm] = useState({})
  var [formCopy, setFormCopy] = useState({})
  var [fetchedFromDB, setFetchedFromDB] = useState(false)

  var [loadStatus, setLoadStatus] = useState({ loading: false, error: false, errorMessage: null })
  var [saveStatus, setSaveStatus] = useState({ error: false, message: null })
  var [formValidationMessage, setFormValidationMessage] = useState(null)

  const clearForm = () => {
    return {
      gitlab_cost_storage: 0,
      gitlab_cost_bandwidth: 0,
      artifactory_cost_storage: 0,
      artifactory_cost_bandwidth: 0,
      ci_cost_persistent_storage: 0,
    }
  }

  const has_changes = () => {
    return JSON.stringify(form) !== JSON.stringify(formCopy)
  }

  const cancel_changes = () => {
    let temp = JSON.parse(JSON.stringify(clearForm()))
    setForm(temp)
    setFormCopy(temp)
  }

  const validate_form = () => {
    // console.log(_.isEmpty(form.personal_project_limits))
    // console.log(form.personal_project_limits)

    if (_.isEmpty(form.gitlab_cost_storage)) return "GitLab Storage Cost is required"
    else if (_.isEmpty(form.gitlab_cost_bandwidth)) return "GitLab Bandwidth Cost is required"
    else if (_.isEmpty(form.artifactory_cost_storage)) return "Artifactory Storage Cost is required"
    else if (_.isEmpty(form.artifactory_cost_bandwidth))
      return "Artifactory Bandwidth Cost is required"
    else if (_.isEmpty(form.ci_cost_persistent_storage))
      return "CI Persistent Storage Cost is required"

    return null
  }

  const save_changes = async () => {
    var valres = validate_form()
    // console.log(valres)
    setFormValidationMessage(valres)
    if (valres === null) {
      // setLoadStatus({ loading: true, error: false, errorMessage: null })
      // await sleep(1000)
      // if (parentOrganizationId === 0)
      setSaveStatus({ error: false, message: null })
      save_cost_settings()
      // else edit_link()
    }
  }

  const reset_form = () => {
    var obj = clearForm()
    setForm(obj)
    setFormCopy(obj)

    setFormValidationMessage(null)
    setSaveStatus(null)
  }

  const hande_change = e => {
    let temp = JSON.parse(JSON.stringify(form))
    temp[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
    setForm(temp)
  }

  const save_cost_settings = () => {
    // fetch_post(`parentOrganization/${orgid}/git`, params, accessToken)
    var pdata = { ...form }

    getAccessTokenSilently().then(accessToken =>
      fetch_post(`admin/system-settings/services-cost`, pdata, accessToken)
        .then(response => {
          if (response.status.code === 201) {
            setSaveStatus({ error: response.status.error, message: response.status.description })
            setFormCopy(form)
          }
        })
        .catch(error => {
          setSaveStatus({ error: true, message: `${error}` })
          Sentry.captureException(error)
          console.log(`Error while adding project link to project: ${error}`)
        })
    )
  }

  const fetch_settings = async porgid => {
    setLoadStatus({ loading: true, error: false, errorMessage: null })
    getAccessTokenSilently()
      .then(accessToken =>
        fetch_get(`admin/system-settings/services-cost`, accessToken)
          .then(response => {
            // console.log(Object.keys(response).length)
            setForm(response)
            setFormCopy(response)
            setFetchedFromDB(true)
            setLoadStatus({ loading: false, error: false, errorMessage: null })
          })
          .catch(error => {
            setLoadStatus({
              loading: false,
              error: true,
              errorMessage: "Oops ! Something went wrong",
            })
            Sentry.captureException(error)
            console.log(`Error while getting project details: ${error}`)
          })
      )
      .catch(error => {
        setLoadStatus({
          loading: false,
          error: true,
          errorMessage: "Oops ! Something went wrong",
        })
        Sentry.captureException(error)
        console.log(`Error while getting access token: ${error}`)
      })
  }

  useEffect(() => {
    reset_form()
    fetch_settings()
    // fetchConfig(parentOrganizationId)
  }, [])

  return (
    <React.Fragment>
      <div className="row">&nbsp;</div>
      <div className="row">
        <div className="col-sm-3">
          <div className="card ">
            <div className="card-header">Gitlab Cost</div>
            <div className="card-body">
              <div className="col">
                <div className="form-group">
                  <Label for="gitlab_cost_storage">Storage Cost</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="gitlab_cost_storage"
                      id="gitlab_cost_storage"
                      value={form.gitlab_cost_storage}
                      onChange={e => hande_change(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>USD</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="form-group">
                  <Label for="gitlab_cost_storage">Bandwidth Cost</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="gitlab_cost_bandwidth"
                      id="gitlab_cost_bandwidth"
                      value={form.gitlab_cost_bandwidth}
                      onChange={e => hande_change(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>USD</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card">
            <div className="card-header">Artifactory Cost</div>
            <div className="card-body">
              <div className="col">
                <div className="form-group">
                  <Label for="gitlab_cost_storage">Storage Cost</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="artifactory_cost_storage"
                      id="artifactory_cost_storage"
                      value={form.artifactory_cost_storage}
                      onChange={e => hande_change(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>USD</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="form-group">
                  <Label for="gitlab_cost_storage">Bandwidth Cost</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="artifactory_cost_bandwidth"
                      id="artifactory_cost_bandwidth"
                      value={form.artifactory_cost_bandwidth}
                      onChange={e => hande_change(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>USD</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card ">
            <div className="card-header">CI Cost</div>
            <div className="card-body">
              <div className="col">
                <div className="form-group">
                  <Label for="gitlab_cost_storage">Persistent storage Cost</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="ci_cost_persistent_storage"
                      id="ci_cost_persistent_storage"
                      value={form.ci_cost_persistent_storage}
                      onChange={e => hande_change(e)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>USD</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>

      <div className="float-left">
        {formValidationMessage !== null && (
          <div className="form-group">
            <span className="text-danger" role="alert">
              <strong>{formValidationMessage}</strong>
            </span>
          </div>
        )}
        {saveStatus !== null && (
          <>
            <div className="form-group">
              {saveStatus.error && (
                <span className="text-danger" role="alert">
                  <strong>{saveStatus.message}</strong>
                </span>
              )}

              {!saveStatus.error && (
                <span className="text-success" role="alert">
                  <strong>{saveStatus.message}</strong>
                </span>
              )}
            </div>
          </>
        )}
      </div>
      <div className="float-right">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => cancel_changes()}
          disabled={!has_changes()}
        >
          Cancel Changes
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => save_changes()}
          disabled={!has_changes()}
        >
          Save Changes
        </button>
        {/* <button
          type="button"
          className="btn btn-primary"
          onClick={() => fetch_settings()}
          // disabled={!has_changes()}
        >
          load
        </button> */}
      </div>
    </React.Fragment>
  )
}

export default ServicesCost

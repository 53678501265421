import React, { useState, useEffect } from "react"
import { Row, Col, Table } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import ModalComponent from "../../components/modal/modal"
import { Card, CardContent, FormControl, InputLabel, Select } from "@material-ui/core"
import "../../styles/summary.scss"
import EchartsBar from "../../components/metrics/metrics-echarts/echarts-bar"
import { fetch_get } from "../../utils/fetch"
import moment from "moment"
import { useAuth0 } from "@auth0/auth0-react"
import EchartsLine from "../../components/metrics/metrics-echarts/echarts-line"
import { useUserOrgs } from "../../hooks/use-user"
// The user information block contains the email address associated with a given
// account (typically a CodeLinaro email address), the user's external email
// address and the picture URL.
//
// By default, the picture URL will reference the primary email address for the
// gravatar URL and be of this format:
//
// https://s.gravatar.com/avatar/cd1055058f4b50f5af02218d8aa3eb3b?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fph.png
//
// NOTE! Sometimes the picture URL does NOT match the primary email address OR the
//       external email address, hence the special handling to allow the URL to be
//       reset.
//
// Scenarios:
// 1. User has email service and both email addresses. The website needs to figure
//    out which email address is being referenced in the URL and provide that as
//    part of the Gravatar guidance. There needs to be an option to switch email
//    addresses.
// 2. User does not have email service but does have both email addresses. The
//    website needs to figure out which email address is being referenced. If it
//    is not the external one, the 'user_metadata.picture' field needs to be set
//    to the correct URL and the email address displayed in the Gravatar guidance.
// 3. User does not have email service and only has one email address. The URL
//    will automatically be correct and the email address needs to be displayed in
//    the Gravatar guidance.
//
// It shouldn't be possible to have email service and only one email address ...

const TransferSummary = props => {
  // const [userName, setUserName] = useState("")
  useState(() => {

  })
  useEffect(() => {
    async function fetchData() {
      await load_project_top_push_users()
    }
    fetchData()
  },[])
  const { orgIndex} = props
  const { getAccessTokenSilently,user } = useAuth0()
  const [projectTopPushUsersLoader, setProjectTopPushUsersLoader] = useState({
    loading: false,
    errorMessage: null,
  })
  const {
    currentOrg: {
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  var [failed_api_calls, set_failed_api_calls] = useState([])
  const [gitTopUsers, setGitTopUsers] = useState([])
  const [MTDobj, setMTDobj] = useState({})
  const [Avgobj, setAvgobj] = useState({})
  const [Visitorobj, setVisitorobj] = useState({})
  const [gitOutBound, setGitOutBound] = useState([])
  const [submitFlag, setSubmitFlag] = useState(null)
  function getParentOrganization() {
    return props.parentOrganization
  }
  function getAdditionalUrlParams() {
    var urlparams = ""
    if (props.isSuperAdmin) {
      urlparams = `?parentOrganization=${getParentOrganization()}`
    } else {
      urlparams = `?index=${orgIndex}`
    }
    return urlparams
  }
  const load_project_top_push_users = () => {
    return new Promise(async (resolve, reject) => {
      var func_name = load_project_top_push_users
      var f = failed_api_calls.filter(item => item !== func_name)
      set_failed_api_calls(f)
      // setProjectTopPushUsersFlashMessage({ stdate: new Date(), message: null, counter: 1 })
      // var urlparams = getAdditionalUrlParams()
      // var urlparamsAmp = urlparams.replace("?", "&")
      setProjectTopPushUsersLoader({ loading: true, errorMessage: null })
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            `admin/data-transfer-management/git`,
            accessToken
          )
        )
        .then(response => {
          setGitTopUsers(
            response.outbound_data_transfer.map(res => ({
              name: res.month,
              value: Number(res.total_bytes_for_month).toFixed(2),
            }))
          )
          setGitOutBound(
            response.recent_outbound_data_transfer.map(res => ({
              name: moment(new Date(res.day)).format('YYYY-MM-DD') ,
              value: Number(res.total_bytes_for_day).toFixed(2),
            }))
          )
          setMTDobj(
            response.data_transfer_cost_mtd[0]
          )
          setAvgobj(
            response.avg_transfer_by_ip[0]
          )
          setVisitorobj(
            response.high_usage_by_ip[0]
          )
          setProjectTopPushUsersLoader({ loading: false, errorMessage: null })

          resolve()
        })
        .catch(error => {
          setProjectTopPushUsersLoader({
            loading: false,
            errorMessage: "failed to load project push users",
            delayMessage: "This is taking longer than expected. Please be patient",
          })
          set_failed_api_calls([...failed_api_calls, func_name])
          // setProjectTopPushUsersFlashMessage({ stdate: null, message: null, counter: 0 })
          // console.log("set date to null")
          resolve()
        })
    })
  }
  return (
    <Layout pageTitle="Account Settings">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">{isSuperAdmin ? user.name : name} - Data Transfer Summary</h1>
      </Header>
      <Content>
        <div>
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              <Card>
                <CardContent className="statisticsContent">
                  <div className="statisticsHead">
                    Outbound Data Transfer
                  </div>
                  <div className="crossLine"></div>
                  <Row className="userStatistics">
                    {!projectTopPushUsersLoader.loading &&
                    projectTopPushUsersLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 350 }}>
                          <EchartsLine
                            barId="gitTopUsers"
                            barData={gitTopUsers}
                            title="Outbound Data Transfer"
                          ></EchartsLine>
                        </CardContent>
                      </>
                    )}
                  </Row>
                </CardContent>
              </Card>
            </Col>
          </Row>

          <Row style={{ marginBottom: "10px" }}>
            <Col xm={4} sm={4} md={4} className="cardCol">
              <Row style={{ marginBottom: "50px" }}>
                <Col>
                  <Card>
                    <CardContent className="cardRowContent">
                        <Col xm={10} sm={10} md={10}>
                          <div className="statisticsHead">
                            Data Transfer Cost MTD
                          </div>
                        </Col>
                        <Col xm={4} sm={4} md={4}>
                          <div className="statisticsHead">{MTDobj.difference}</div>
                        </Col>
                    </CardContent>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginBottom: "50px" }}>
                <Col>
                  <Card>
                    <CardContent className="cardRowContent">
                      <Col xm={10} sm={10} md={10}>
                        <div className="statisticsHead">
                          Avg Transfer by Visitor IP
                        </div>
                      </Col>
                      <Col xm={4} sm={4} md={4}>
                        <div className="statisticsHead">{Avgobj.average_bytes}</div>
                      </Col>
                    </CardContent>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginBottom: "50px" }}>
                <Col>
                  <Card>
                    <CardContent className="cardRowContent">
                      <Col xm={10} sm={10} md={10}>
                        <div className="statisticsHead">
                          High Usage Visitors
                        </div>
                      </Col>
                      <Col xm={4} sm={4} md={4}>
                        <div className="statisticsHead">{Visitorobj.number_of_ips}</div>
                      </Col>
                    </CardContent>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xm={8} sm={8} md={8}>
              <Card>
                <CardContent className="statisticsContent">
                  <div className="statisticsHead">
                    Recent Outbound Data Transfer
                  </div>
                  <div className="crossLine"></div>
                  <Row className="userStatistics">
                    {!projectTopPushUsersLoader.loading &&
                    projectTopPushUsersLoader.errorMessage === null && (
                      <>
                        <CardContent style={{ width: "100%", height: 350 }}>
                          <EchartsBar
                            barId="gitOutbound"
                            barData={gitOutBound}
                            title="Recent Outbound Data Transfer"
                          ></EchartsBar>
                        </CardContent>
                      </>
                    )}
                  </Row>
                </CardContent>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  )
}

export default ModalComponent()(TransferSummary)

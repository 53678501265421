import React from "react"
import { Row, Col, Button } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import ManageAccessControl from "../../components/project-form/manage-access-control"
import { Loading } from "../../components/loading"
import { css } from "glamor"
import { navigate } from "gatsby"

const ProjectEdit = props => {
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin, isSystemAdmin },
    },
  } = useUserOrgs()

  if (loading) {
    return <Loading />
  }

  return (
    <Layout pageTitle="Access Control">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">Access Control</h1>
      </Header>
      <Content>
        <Row>
          <Col>
            <ManageAccessControl
              parentOrganization={props.parentOrganization}
              projectSlug={props.projectSlug}
              orgIndex={index}
              isSuperAdmin={isSuperAdmin}
              isSystemAdmin={isSystemAdmin} 
              isAccessControlPage={true} />
          </Col>
        </Row>
        {props.location.state?.from ? (
          <Row>
            <Col
              {...css({
                textAlign: "right",
                paddingRight: "15px",
              })}
            >
              <Button
                onClick={() => {
                  navigate(props.location.state.from)
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        ) : null}
      </Content>
    </Layout>
  )
}

export default ProjectEdit

import React, { useState, useEffect } from "react"
import { Router } from "@reach/router"
import UserAdmin from "./secure/useradmin"
import ProjectAdmin from "./secure/projectadmin"
import ManageTeam from "./secure/teamadmin"
import CreateTeam from "./secure/create-team"
import AccountSettings from "./secure/settings"
import ErrorPage from "./404"
import BulkUsers from "./secure/bulk-users"
import CreateUser from "./secure/create-user"
import CreateProject from "./secure/create-project"
import ViewProject from "./secure/view-project"
import EditProject from "./secure/edit-project"
import AccessProject from "./secure/access-project"
import ManageOrganization from "./secure/manage-organization"
import ParentOrganizationSettings from "./secure/parent-organization-settings"
import SystemSettings from "./secure/system-settings"
import ProtectedRoute from "../components/protected-route"
import {
  UserAdminRoute,
  ProjectAdminRoute,
  SuperAdminRoute,
  SystemAdminRoute,
} from "../components/admin-route"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../components/loading"
import { Error } from "../components/error"
import { fetch_get } from "../utils/fetch"
import TransferSummary from "./secure/transfersummary"

const Secure = () => {
  const { isLoading, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <Error message={error.message} />}
      <Router>
        <ProtectedRoute path="/secure/settings" component={AccountSettings} />
        <ProtectedRoute path="/secure/summary" component={TransferSummary} />
        <SuperAdminRoute path="/secure/organizations" component={ManageOrganization} />
        <SuperAdminRoute path="secure/organizations/:orgKey/settings" component={ParentOrganizationSettings} />
        <SuperAdminRoute path="/secure/teamadmin" component={ManageTeam} />
        <SuperAdminRoute path="/secure/create-team" component={CreateTeam} />
        <UserAdminRoute path="/secure/useradmin" component={UserAdmin} />
        <SuperAdminRoute path="/secure/bulk-users" component={BulkUsers} />
        <UserAdminRoute path="/secure/create-user" component={CreateUser} />
        <ProjectAdminRoute path="/secure/projectadmin" component={ProjectAdmin} />
        <SystemAdminRoute path="/secure/create-project" component={CreateProject} />
        <SuperAdminRoute
          path="/secure/projects/:parentOrganization/:projectSlug/edit"
          component={EditProject}
          allow={"admin"}
        />
        <SuperAdminRoute
          path="/secure/projects/:parentOrganization/:projectSlug/access-control"
          component={AccessProject}
          allow={"admin"}
        />
        <SuperAdminRoute
          path="/secure/projects/:projectSlug"
          component={ViewProject}
          allow={"admin"}
        />
        <SuperAdminRoute path="/secure/system-settings" component={SystemSettings} />

        <ErrorPage default />
      </Router>
    </>
  )
}

export default Secure

import React, { useState, useEffect } from "react"
import { Row, Col, Table, Button } from "reactstrap"
import { fetch_get } from "../../utils/fetch"
import { PropagateLoader } from "react-spinners"
import { useAuth0 } from "@auth0/auth0-react"
import Pagination from "@material-ui/lab/Pagination"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import * as Sentry from "@sentry/gatsby"
import { css } from "glamor"
import _ from "lodash"

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}))

const ViewLogs = props => {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [pageCount, setPageCount] = useState(1)
  const [pageTotal, setPageTotal] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [logs, setLogs] = useState([])
  const [noLogs, setNoLogs] = useState("There are no logs.")

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap()
    return <Tooltip arrow classes={classes} {...props} />
  }

  const getIcon = item => {
    let res = null
    switch (item.status) {
      case "pending":
        res = (
          <BootstrapTooltip title={"Pending"} placement="top">
            <img height="30" src={`/icon-pending.svg`} alt="service" />
          </BootstrapTooltip>
        )
        break
      case "completed":
        res = (
          <BootstrapTooltip title={"Completed"} placement="top">
            <img height="30" src={`/icon-completed.svg`} alt="service" />
          </BootstrapTooltip>
        )
        break
      case "failed":
        res = (
          <BootstrapTooltip title={`Failed, Error Message: ${item.message}`} placement="top">
            <img height="30" src={`/icon-failed.svg`} alt="service" />
          </BootstrapTooltip>
        )
        break
      case "in-progress":
        res = (
          <BootstrapTooltip title={"In-Progress"} placement="top">
            <img
              style={{ animation: "spinner-border 1.5s linear infinite" }}
              height="30"
              src={`/icon-process.svg`}
              alt="service"
            />
          </BootstrapTooltip>
        )
        break
      default:
        break
    }
    return res
  }

  const get_logs = url => {
    setLoading(true)
    setLogs([])
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setLoading(false)
        setLogs(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
        setPageTotal(totalRecords)
      })
      .catch(error => {
        setLoading(false)
        setNoLogs("An error occurred while getting the list of logs.")
        Sentry.captureException(error)
        console.log(`Error while getting project logs: ${error}`)
      })
  }

  useEffect(() => {
    if (props.activeTab === "2") {
      setCurrentPage(1)
      const url = `ui/users/bulk?page=1`
      get_logs(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab, props.users, props.viewOnly])

  function onPageChange(event, page) {
    setCurrentPage(page)
    let url = `ui/users/bulk?page=${page}`
    get_logs(url)
  }

  const onRefresh = () => {
    setCurrentPage(1)
    const url = `ui/users/bulk?page=1`
    get_logs(url)
  }

  const logsTableRender = () => {
    return logs.length > 0 ? (
      <div {...css({ position: "relative" })}>
        <div
          {...css({
            position: "absolute",
            // top: 0,
            // left: 0,
            zIndex: "99",
          })}
        >
          <Button className="float-right" color={"primary"} onClick={onRefresh}>
            {/* <MdRefresh className=" mr-2" /> */}
            Refresh
          </Button>
        </div>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <span {...css(styles.pageTotal)}>{`Total ${pageTotal} items`}</span>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={onPageChange}
              variant="outlined"
              shape="rounded"
            />
          </Col>
        </Row>
        <Row className={`my-3`}>
          <Col>
            <Table bordered>
              <thead>
                <tr>
                  <th rowSpan={2}>Status</th>
                  <th rowSpan={2}>Username</th>
                  <th rowSpan={2}>First Name</th>
                  <th rowSpan={2}>Last Name</th>
                  <th rowSpan={2}>Display Name</th>
                  <th rowSpan={2}>External Email Address</th>
                  <th rowSpan={2}>Parent Organization</th>
                  <th rowSpan={2}>Organization</th>
                  <th rowSpan={2}>Role</th>
                  <th colSpan={5}>Services</th>
                  <th rowSpan={2}>welcomeEmail</th>
                </tr>
                <tr>
                  <th rowSpan={1}>Email</th>
                  <th rowSpan={1}>Git</th>
                  <th rowSpan={1}>Support</th>
                  <th rowSpan={1}>Artifacts</th>
                  <th rowSpan={1}>Wiki</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{getIcon(item)}</td>
                      <td>{item.username}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.displayName}</td>
                      <td>{item.externalEmail}</td>
                      <td>{item.parentOrganization}</td>
                      <td>{item.organization}</td>
                      <td>{item.role}</td>
                      <td>{_.isNil(item.email) ? "" : item.email ? "True" : "False"}</td>
                      <td>{_.isNil(item.gitlab) ? "" : item.gitlab ? "True" : "False"}</td>
                      <td>
                        {_.isNil(item.servicedesk) ? "" : item.servicedesk ? "True" : "False"}
                      </td>
                      <td>
                        {_.isNil(item.artifactory) ? "" : item.artifactory ? "True" : "False"}
                      </td>
                      <td>{_.isNil(item.wiki) ? "" : item.wiki ? "True" : "False"}</td>
                      <td>
                        {_.isNil(item.welcomeEmail) ? "" : item.welcomeEmail ? "True" : "False"}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <span {...css(styles.pageTotal)}>{`Total ${pageTotal} items`}</span>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={onPageChange}
              variant="outlined"
              shape="rounded"
            />
          </Col>
        </Row>
      </div>
    ) : (
      <Row>
        <Col xs={{ size: 12 }}>
          <p
            {...css({
              marginTop: "45px",
            })}
          >
            {noLogs}
          </p>
        </Col>
      </Row>
    )
  }

  return (
    <>
      {loading ? (
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={{ size: 12 }}
            sm={{ size: 4, offset: 4 }}
          >
            <PropagateLoader
              className="align-self-center"
              size={20}
              color={"#9c3"}
              loading={true}
            />
          </Col>
        </Row>
      ) : (
        logsTableRender()
      )}
    </>
  )
}

export default ViewLogs

const styles = {
  pageTotal: {
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
    lineHeight: "30px",
    marginRight: "5px",
  },
}

import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import ProjectFormView from "../../components/project-form/project-form-view"
import { Loading } from "../../components/loading"

const ProjectDetail = props => {
  const { loading } = useUserOrgs()

  if (loading) {
    return <Loading />
  }
  return (
    <Layout pageTitle="Project Detail">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">Project Detail</h1>
      </Header>
      <Content>
        <Row>
          <Col>
            <ProjectFormView viewOnly={true} projectSlug={props.projectSlug} />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ProjectDetail

import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import SSContainer from "../../components/secure/system-settings/container"
import { Link } from "gatsby"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import { Loading } from "../../components/loading"

const SystemSettings = props => {
  const {
    loading,
    currentOrg: { index },
  } = useUserOrgs()
  if (loading) {
    return <Loading />
  }
  return (
    <Layout pageTitle="System Settings">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">
          {/*<Link to={`/`}>*/}
          {/*  <FaArrowAltCircleLeft className="goBackIcon" />*/}
          {/*</Link>*/}
          System Settings
        </h1>
      </Header>
      <Content>
        {/* wow test */}
        <SSContainer />
        {/* <Row>
          <Col>
            <TeamFormCreate orgIndex={index} />
          </Col>
        </Row> */}
      </Content>
    </Layout>
  )
}

export default SystemSettings
